import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";



// Images
import bnrImg from "../../images/banner/bnr4.jpg";
import testPic1 from "../../images/testimonials/pic1.jpg";

const BlogFaaliyetFirma = () => {
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>Blog Details</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Blog Details
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8 col-xl-8 mb-30 mb-md-60">
                <div className="blog-lg blog-single">
                  <div className="info-bx">
                    <ul className="post-meta">
                      <li className="author">
                        <img src={testPic1} alt="" />
                        By <Link to="#">Evren Otogaz</Link>
                      </li>
                      <li className="date">
                        <Link to="#">05/11/2023</Link>
                      </li>
                    </ul>
                    <div className="ttr-post-title">
                      <h3 className="post-title">
                        Turkiyede faaliyet gosteren firmalar
                      </h3>
                    </div>
                    <div className="ttr-post-text">
                      <p>
                        LPG dönüşümü, benzinli araçların yakıt sistemlerinin
                        modifiye edilerek LPG kullanmalarını sağlayan bir
                        işlemdir. LPG dönüşümü yaptıran araç sahipleri, yakıt
                        tasarrufu, çevre dostu ve motor ömrü gibi avantajlardan
                        yararlanabilirler. LPG dönüşümü yaptırmak isteyenler,
                        Türkiye’de faaliyet gösteren pek çok LPG markası
                        arasından seçim yapabilirler.
                      </p>
                      <p>
                        Türkiye’de en çok tercih edilen LPG markaları arasında
                        Atiker, BRC, Prins, Lovato, Zavoli, Tartarini,
                        Landirenzo, OMVL, Ekotech, KitMTM, Teleflex, Bedini,
                        Aldesa, Vikars, Logas, Cangas ve Mimgas gibi markalar
                        bulunmaktadır1234. Bu markaların fiyatları, kalitesi,
                        performansı, servis ağı, garanti süresi ve uyumluluğu
                        gibi kriterlere göre değişiklik gösterebilir. Bu
                        nedenle, araç sahipleri, araçlarına en uygun LPG
                        markasını seçerken, bu kriterleri göz önünde
                        bulundurmalı ve güvenilir bir servis ile
                        çalışmalıdırlar.
                      </p>

                  
                    </div>
                    <div className="ttr-post-footer">
                      <div className="post-tags">
                        <strong>TAGS:</strong>
                      : Tagler: LPG dönüşümü, LPG dönüşüm
                        fiyatları, LPG dönüşüm avantajları, LPG dönüşüm nasıl
                        yapılır, LPG dönüşüm markaları, LPG dönüşüm servisi,
                        Atiker LPG, BRC LPG, Prins LPG, Lovato LPG, Zavoli LPG,
                        Tartarini LPG, Landirenzo LPG, OMVL LPG, Ekotech LPG,
                        KitMTM LPG, Teleflex LPG, Bedini LPG, Aldesa LPG, Vikars
                        LPG, Logas LPG, Cangas LPG, Mimgas LPG
                    
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-xl-4 mb-30"></div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default BlogFaaliyetFirma;
