const executeScroll = (ref) => ref.current.scrollIntoView()    
function calculateLPGSavings(fuelConsumption, fuelCost, lpgCost, lpgInstallationCost, averageKmPerMonth, carInfo) {
    // Convert L/100km to L/km
    const fuelConsumptionPerKm = fuelConsumption / 100;
    
    // Calculate monthly fuel costs
    const monthlyFuelCost = (fuelConsumptionPerKm * averageKmPerMonth * fuelCost);
    const monthlyLPGCost = (fuelConsumptionPerKm * 1.2 * averageKmPerMonth * lpgCost); // 20% more consumption for LPG
    
    // Calculate monthly savings
    const monthlySavings = monthlyFuelCost - monthlyLPGCost;
    
    // Calculate payback period in months
    const paybackPeriod = lpgInstallationCost / monthlySavings;
    
    // Calculate savings percentage
    const savingsPercentage = (monthlySavings / monthlyFuelCost) * 100;
    
    // Determine if it's suitable for the car (you'd need to implement this logic based on carInfo)
    
    // Prepare the result object
    const result = {
        aylikBenzinMaliyeti: Math.round(monthlyFuelCost),
        aylikLpgMaliyeti: Math.round(monthlyLPGCost),
        aylikTasarruf: Math.round(monthlySavings),
        lpgKurulumMaliyeti: lpgInstallationCost,
        lpgSavingPercentage: Math.round(savingsPercentage),
        tasarrufSureleri: `Yaklaşık ${Math.round(paybackPeriod)} ay`,
        restText: ''
    };
    
    return result;
    }

    export {calculateLPGSavings, executeScroll}