import React  from 'react';

// Images
import pic6 from "../../images/about/pic6.jpg";

const ChooseUsSection = () => {
		return(
			<>
				<section className="section-area bg-white section-sp1">
					<div className="container">
						<div className="row align-items-center">
							<div className="col-lg-6 mb-30">
								<div className="choose-media">
									<img src={pic6} alt=""/>
								</div>
							</div>
							<div className="col-lg-6 mb-30">
								<div className="heading-bx mb-30">
									{/* <h6 className="title-ext text-primary">Neden Bizi Seçmelisiniz</h6> */}
									<h2 className="title mb-0">Neden Bizi Seçmelisiniz</h2>
									<p>Montajını gerçekleştirdiğimiz markalar Türkiye genelinde yaygın servis ağı ve garanti belgesine sahiptir. Benzinli ve enjeksiyonlu tüm araçlara TSE 12047 ve HYB sertifikalı yüksek kalite standartlarında konusunda uzman personellerce LPG dönüşümü, otogaz dönüşümü hizmeti vermekteyiz.</p>
								</div>
								<div className="progress-area">
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Kaliteli Servis</h6>
											<h6 className="progress-value">100%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "100%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Tecrübeli Ekİp</h6>
											<h6 className="progress-value">95%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "90%"}}></div>
										</div>
									</div>
									<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Garantili Servis &amp; Ürün</h6>
											<h6 className="progress-value">70%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "70%"}}></div>
										</div>
									</div>	<div className="progress-box style1 appear">
										<div className="progress-info">
											<h6 className="title">Teknolojiyle Uyum</h6>
											<h6 className="progress-value">95%</h6>
										</div>
										<div className="progress">
											<div className="progress-bar bg-primary" style={{width: "90%"}}></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</>
		);
}

export default ChooseUsSection;