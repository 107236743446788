import React from "react";

// Images
import bg1 from "../../images/background/bg1.png";
import pic1 from "../../images/about/mahir_zirh.jpeg";

export const AboutUsOneSection = () => {
  return (
    <>
      <section
        className="section-area section-sp1 bg-white"
        style={{
          backgroundImage: "url(" + bg1 + ")",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5 mb-40">
              <div className="heading-bx mb-4">
                <h6 className="title-ext text-primary">BİZ KİMİZ</h6>
                <h2 className="title">
                  TECRÜBEYİ TEKNOLOJİYLE BİRLEŞTİRİYORUZ{" "}
                </h2>
                <p>
                  Bütçenizi aşmayan ödeme seçenekleri ile aracınıza en uygun LPG
                  kiti montajını gerçekleştiriyoruz. Montaj sonrası süreçte de
                  periyodik bakım ve gerekli kontroller, konusunda uzman ve
                  sertifikalı personellerimiz tarafından büyük bir titizlikle
                  gerçekleştirilmektedir.
                </p>
              </div>
            </div>
            <div className="col-lg-7 mb-30">
              <div className="about-media">
                <div className="media">
                </div>
                <div className="about-contact bg-primary text-white">
                  <h6 className="title-ext text-white">Yardımcı Olalım</h6>
                  <a
                    href="tel:0212 564 11 34"
                    className="number mb-15 text-white"
                  >
                    +902125641134{" "}
                  </a>
                  <p className="mb-20">
                    Bakim, Tamir veya montaj konusunda bilgi almak icin linke
                    tıklayın.
                  </p>
                  <a href="tel:0212 564 11 34" className="btn btn-light">
                    Şimdi Arayın
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsOneSection;
