import React, {Component} from 'react';

// Layout
//import Header from "../layout/header";
import Footer from "../layout/footer";

// Elements
import Header from "../layout/header";
import AboutUsOneSection from "../elements/about-us-1";
import ServicesSection from "../elements/services-1";
import ChooseUsSection from "../elements/choose-us";
import BlogSection from '../elements/blog-section';
import SavingsBot from '../elements/savingsBot';
import ImageBig from '../image-big';
const Index=()=>{	
	
		
		return (
			<>
				<Header />
				<ImageBig />
				<SavingsBot />
				<AboutUsOneSection />
				<ServicesSection />
				<ChooseUsSection />
				<BlogSection />
				<Footer />
				
			</>
		);
}

export default Index;