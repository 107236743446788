import React from "react";

// Images
import bg2 from "../../images/background/bg2.png";
import tank from "../../images/services/tank.png"
import { services } from "../../vendor/data/services";
const ServicesSection = () => {
  return (
    <>
      <section
        className="section-area bg-primary service-wraper1 section-sp2"
        style={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "right top",
        }}
      >
        <div className="container">
          <div className="heading-bx text-white d-lg-flex d-md-block align-items-end justify-content-between">
            <div className="clearfix">
              <h3>HİZMETLERİMİZ</h3>
            </div>
          </div>
          <div className="row spno">
            {services.map((srv, index) => (
              <div className="col-xl-4 col-lg-4 col-md-6" key={index}>
                <div className="feature-container bg-gray-alt feature-bx1 mb-sm-30">
                  <div className="icon-lg mb-10 text-primary">
                    <img src={srv.image} alt="" />
                  </div>
                  <div className="icon-content">
                    <h5 className="ttr-tilte">{srv.header}</h5>
                    <p>{srv.definition}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServicesSection;
