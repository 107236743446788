import React, { useEffect,useState } from 'react';
import {animateScroll} from 'react-scroll';

const BackToTop = (props) => {

    const scrollToTop = () => {
        animateScroll.scrollToTop();
    }
    
    const [offset, setOffset] = useState(props.offset || 1000);
    const [show, setShow] = useState(false);
    
    useEffect(() => {
        window.addEventListener("scroll",()=>{
            if(window.pageYOffset > offset){
                setShow(true);
            }
            else{
                setShow(false);
            }
        })
    }, [offset]);
    
    return(
        <>
            {show && (
                <button className="back-to-top" type="button" onClick={scrollToTop}>
                    <i className="fa fa-chevron-up"></i>
                </button>
            )}
        </>
    );
            }    
export default BackToTop;