import React from "react";
import { Link } from "react-router-dom";

// Layout
import Header from "../layout/header";
import Footer from "../layout/footer";

// Images
import bnrImg from "../../images/banner/bnr4.jpg";
import testPic1 from "../../images/testimonials/pic1.jpg";

const BlogLPGTankDegisimi = () => {
  return (
    <>
      <Header />

      <div className="page-content bg-white">
        <div
          className="page-banner ovbl-dark"
          style={{ backgroundImage: "url(" + bnrImg + ")" }}
        >
          <div className="container">
            <div className="page-banner-entry text-center">
              <h1>
                <span>LPG Tank Değişimi: Neden ve Nasıl Yapılır?</span>
              </h1>
              <nav aria-label="breadcrumb" className="breadcrumb-row">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="las la-home"></i>Anasayfa
                    </Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>

        <section className="section-area section-sp1 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-8 col-xl-8 mb-30 mb-md-60">
                <div className="blog-lg blog-single">
                  <div className="info-bx">
                    <ul className="post-meta">
                      <li className="author">
                        <img src={testPic1} alt="" />
                        Yazar: <Link to="#">Evren Otogaz</Link>
                      </li>
                      <li className="date">
                        <Link to="#">07/07/2024</Link>
                      </li>
                    </ul>
                    <div className="ttr-post-title">
                      <h3 className="post-title">
                        LPG Tank Değişimi: Neden ve Nasıl Yapılır?
                      </h3>
                    </div>
                    <div className="ttr-post-text">
                      <p>
                        LPG tankı, kullanım için 10 yıllık bir süre için onaylanır. Bu süre sonunda gaz tüpü yenisiyle değiştirilmeli veya yeniden test edilmelidir. LPG tankının değiştirilmesi zaman alıcı veya karmaşık bir prosedür değildir, ancak kendi güvenliğiniz için LPG sistemleri konusunda uzman bir servisin hizmetlerinden yararlanmak en iyisidir.
                      </p>
                      <p>
                        <strong>Arabanın Gaz Tankı Nasıl Değiştirilir?</strong>
                        <br />
                        Evren Otogaz servisinde, LPG sistemli araçların bakım ve onarımının yanı sıra araç LPG tankı değişimi de yapılabilmektedir. Bu is, Evren Otogaz'ın uzman çalışanları gibi uzmanlara emanet edilmelidir.
                      </p>
                      <blockquote>
                        "Evde kendi başınıza değiştirirken yapılacak herhangi bir hata çok fazla hasara neden olabilir ve görünürdeki tasarruf ciddi bir masrafa dönüşebilir. Bu nedenle, kendi başınıza yapmamalısınız. Ayrıca, yönetmeliklere aykırı olduğu için başka bir araçta kullanılmış bir tankı aracınıza takmamalısınız. Ek olarak, LPG tesisatının sık sık teşhis gerektirdiğini ve bunun bir garajda yapılamayacağını unutmayın."
                        <footer>Evren Otogaz Başkanı</footer>
                      </blockquote>
                      <p>
                        <strong>LPG Tank Değişim Adımları</strong>
                        <br />
                        1. Yakıt tankının boşaltılması
                        <br />
                        2. Çok valfin sökülmesi
                        <br />
                        3. Bağlantı elemanlarının sökülmesi
                        <br />
                        4. Yeni tankın takılması
                        <br />
                        5. Çok valfin durumunun değerlendirilmesi
                        <br />
                        6. Sızdırmazlık kontrolü
                      </p>
                      <p>
                        <strong>Yeni LPG Tankı İçin Garanti</strong>
                        <br />
                        Evren Otogaz olarak, hızlı LPG tank değişim hizmeti sunuyor, yeni takılan silindir için tam garanti sağlıyoruz. Ayrıca hizmetlerimiz için uygun fiyatlar sunuyoruz.
                      </p>
                      <p>
                        <strong>LPG Tank Değişimi ?</strong>
                        <br />
                        LPG tankının kullanım süresi, üretim tarihinden itibaren 10 yıl sonra sona erer. 
                      </p>
                      
                      <blockquote>

"LPG tankı değiştirildikten sonra, atölyede tüm sistem sızıntılara karşı kontrol edilir. Eğer silindiri değiştirmek istemiyorsak, yeniden yasal hale getirebiliriz. Ancak, tankın maksimum kullanım ömrünün üretim tarihinden itibaren 20 yıl olduğu unutulmamalıdır. LPG tankı ayrıca mekanik hasar ve çarpışma durumunda da değiştirilmelidir. Yeniden yasal hale getirme işlemi için sadece 67R homologasyon işaretine sahip silindirler (tüm LPG tankları buna sahiptir) kabul edilebilir."
</blockquote>
                    
                    </div>
                    <div className="ttr-post-footer">
                      <div className="post-tags">
                        <strong>Etiketler:</strong>
                        <Link to="#">LPG tank değişimi</Link>
                        <Link to="#">LPG sistem bakımı</Link>
                        <Link to="#">Araç güvenliği</Link>
                        <Link to="#">LPG tank ömrü</Link>
                        <Link to="#">Otogaz güvenliği</Link>
                        <Link to="#">LPG sistem kontrolü</Link>
                        <Link to="#">Araç yakıt sistemi bakımı</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-4 col-xl-4 mb-30"></div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
};

export default BlogLPGTankDegisimi;