import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, Cell, ResponsiveContainer, Label } from 'recharts';


const COLORS = ['#0088FE', '#00C49F'];

export function Doughnut({ saving, savingDuration}) {
    const data = [
        { name: 'Installation Cost', value: saving.lpgKurulumMaliyeti },
        { name: 'Saving', value: saving.aylikTasarruf*savingDuration*12 },
      ];
      const RADIAN = Math.PI / 180;

      const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
      
        return (
          <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
          </text>
        );
      };
      
    return (
        <div > 
      <PieChart width={375} height={400}>
        <Pie
          data={data}
          cx={200}
          cy={200}
          innerRadius={80}
          label
          outerRadius={100}
          fill="#8884d8"
          paddingAngle={5}
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
   <Label
  content={({ viewBox }) => {
    const { cx, cy } = viewBox;
    return (
      <g>
        <text x={cx} y={cy} textAnchor="middle" dominantBaseline="central">
          <tspan x={cx} dy="-1.8em" fontSize="16" >
            4 Yilda 
          </tspan>
          <tspan x={cx} dy="0.9em" fontSize="16" >
             edilen tasarruf
          </tspan>
          <tspan x={cx} dy="1em" fontSize="20" >
            {saving.aylikTasarruf*savingDuration*12 - saving.lpgKurulumMaliyeti}₺
          </tspan>
          <tspan x={cx} dy="1.8em" fontSize="11" >
            Tasarruf orani: {saving.lpgSavingPercentage}%
          </tspan>
        
        </text>
      </g>
    );
  }}
/>
               
        </Pie>
        
        
      </PieChart>
      <div>
        <div className='w-100 h-100' style={{background:COLORS[0]}}>

        </div>
      </div>
      </div>
    );
}
