import React from "react";
import { Link } from "react-router-dom";

// Import Images
import bg2 from "../../images/background/bg2.jpg";
import gridPic2 from "../../images/blog/grid/pic2.jpg";
import testiPic1 from "../../images/testimonials/pic1.jpg";
import { blogs } from "../../vendor/data/services.js";
const BlogSection = () => {
  return (
    <>
      <section
        className="section-area ovbl-dark blog-wraper1 section-sp1"
        style={{
          backgroundImage: "url(" + bg2 + ")",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundSize: "cover",
        }}
      >
        <div className="container">
          <div className="row">
            <div className="blog-custom">
  
              {blogs.map((blg) => (
                <div className="blog-card blog-list style-1 bg-white shadow">
                  <div className="post-media">
                    <Link to="/blog-details">
                      <img src={blg.image} alt="" />
                    </Link>
                  </div>
                  <div className="post-info">
                    <h5 className="post-title">
                      <Link to={blg.path}>{blg.header}</Link>
                    </h5>
                    <div className="post-content">
                      <p className="mb-0 content-text">{blg.body}</p>
                    </div>
                    <ul className="post-meta">
                      <li className="author">
                        <img src={blg.image} alt="" />
                        By <Link to="#">Evren Otogaz</Link>
                      </li>
                      <li className="date">
                        <Link to="#">{blg.date}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogSection;
