import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// Elements
import BackToTop from "./elements/back-top";
import PageScrollTop from "./elements/page-scroll-top";

// All Pages Router
import Index from "./pages/index";
import BlogDetails from "./pages/blogNedenLpg";
import BlogFaaliyetFirma from "./pages/blogFaaliyetFirma";
import BlogNedenHalaKarli from "./pages/blogNedenHalaKarli";
import BlogOtomotivSektorununGelecegi from "./pages/blogOtomotivSektorununGelecegi";
import BlogLPGTankDegisimi from "./pages/BlogLPGTankDegisimi";
import BlogLPGvsElektrikliAraclar from "./pages/BlogLPGvsElektrikliAraclar";

const Markup = () => {
  return (
    <>
      {" "}
      <BrowserRouter basename={"/"}>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/blog-neden-lpg" exact component={BlogDetails} />
          <Route path="/blog-hala-karli" exact component={BlogNedenHalaKarli} />
          <Route path="/blog-faaliyet-firma" exact component={BlogFaaliyetFirma} />
          <Route path="/blog-otomotiv-sektorunun-gelecegi" exact component={BlogOtomotivSektorununGelecegi} />
          <Route path="/blog-lpg-tank-degisimi" exact component={BlogLPGTankDegisimi} />
          <Route path="/blog-lpg-vs-elektrikli-araclar" exact component={BlogLPGvsElektrikliAraclar} />
        </Switch>

        <PageScrollTop />
      </BrowserRouter>
      <BackToTop />
    </>
  );
};

export default Markup;
