
import  tasarrufEvren from "../images/tasarruf-evren.png" 

const ImageBig = ()=>{

    return (
        <div className="col-lg-12">
            <div className="d-flex justify-content-center align-items-center" style={{height: '100%', position: 'relative'}}>
                <img src={tasarrufEvren} alt="" style={{width: 'auto', height: 'auto', maxWidth: '100%'}} />
                {/* <div className=" text-white" style={{position: 'absolute', top: '30%', left: '30%', transform: 'translate(-50%, -50%)'}}> */}
               {/* <div className="d-flex flex-column justify-content-start align-items-start">
               <span>Yuksek Tasarruf</span>
            <span>Yuksek Performans</span>                

               </div>
        

                </div>
                <div className=" text-white" style={{position: 'absolute', top: '80%', left: '60%', transform: 'translate(-50%, -50%)', width:"100%"}}>
                <span style={{width:"100%"}}>
                PERFORMANS KAYBI YAŞAMADAN <br/>
%40'A VARAN TASARRUF, <br/>
 BİZİM İLE MÜMKÜN
                </span>
               </div> */}
            </div>
        </div>
    )
}
export default ImageBig;

