import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Sticky from "react-stickynode";

import logo from "../../images/evren-side-laci.png";
import logoWhite from "../../images/evren-side.png";

export const AboutSection = () => {
  useEffect(() => {
    // Mobile Menu Open
    var menuIcon = document.querySelector(".menuicon");
    var menuLinks = document.querySelector(".menu-links");
    var menuClose = document.getElementById("menuClose");

    menuIcon.addEventListener("click", function () {
      menuLinks.classList.add("show");
    });

    menuClose.addEventListener("click", function () {
      menuLinks.classList.remove("show");
    });

    // Mobile Submenu Open Close Function
    var navMenu = [].slice.call(
      document.querySelectorAll(".menu-links > ul > li")
    );
    for (var y = 0; y < navMenu.length; y++) {
      navMenu[y].addEventListener("click", function () {
        menuClick(this);
      });
    }

    function menuClick(current) {
      const active = current.classList.contains("open");
      navMenu.forEach((el) => el.classList.remove("open"));

      if (active) {
        current.classList.remove("open");
      } else {
        current.classList.add("open");
      }
    }
  }, []);

  return (
    <>
      <header className="header header-transparent rs-nav">
        <Sticky enabled={true} className="sticky-header navbar-expand-lg">
          <div className="menu-bar clearfix ">
            <div className="container clearfix">
              <div className="menu-logo">
                <Link to="/" className="main-logo">
                  <img src={logoWhite} alt="" />
                </Link>
                <Link to="/" className="sticky-logo">
                  <img src={logo} alt="" />
                </Link>
              </div>
              <button
                className="navbar-toggler collapsed menuicon justify-content-end"
                type="button"
                data-toggle="collapse"
                data-target="#menuDropdown"
                aria-controls="menuDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span></span>
                <span></span>
              </button>

              <div
                className="menu-links navbar-collapse collapse justify-content-center"
                id="menuDropdown"
              >
                <div className="menu-logo">
                  <Link to="/">
                    <img src={logoWhite} alt="" />
                  </Link>
                </div>

                <ul className="social-media">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.google.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-google-plus"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.linkedin.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://twitter.com/"
                      rel="noreferrer"
                      className="btn btn-primary"
                    >
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                </ul>
                <div className="menu-close" id="menuClose">
                  <i className="ti-close"></i>
                </div>
              </div>
            </div>
          </div>
        </Sticky>
      </header>
    </>
  );
};

export default AboutSection;
