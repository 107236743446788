import React, { useEffect, useRef, useState } from "react";

// Images
import pic6 from "../../images/about/pic6.jpg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { Doughnut } from "../components/Doughnut";
import axios from "axios";
import LegendItem from "../components/legendItem";
import ThreeDots from "../components/threeDots";
import Typewriter from "../components/TypeWriter";
import { calculateLPGSavings, executeScroll } from "../../vendor/helper";

const SavingsBot = () => {
  const [fuelConsumptionValue, setFuelConsumptionValue] = useState(5.8);
  const [fuelCostValue, setFuelCostValue] = useState(40);
  const [lpgCostValue, setLpgCostValue] = useState(20);
  const [savingsDuration, setSavingsDuration] = useState(4);
const [mechanicIdea, setMechanicIdea] = useState('');
const [aiLoading, setAiLoading] = useState(false);
  const [lpgInstallationCostValue, setLpgInstallationCostValue] =
    useState(18000);
  const [averageKmForMonthValue, setAverageKmForMonthValue] = useState(2500);
  const [savingResponse, setSavingResponse] = useState({});
  const [carInfo, setCarInfo] = useState('');
  const aiRef = useRef(null);


 
  const calculateSavings = async () => {
    setAiLoading(true);
    executeScroll(aiRef);
   try {
 
    const response = await axios.post(
      "https://api.omerzirh.com/api/calculate",
      {
        savingDetails:savingResponse,
        savingsDuration,
        carInfo
      },
     { headers: {
        'Content-Type': 'application/json',
      }}
    );
    setMechanicIdea(response.data.restText)
    setAiLoading(false);
  } catch (error) {
      console.error("Error fetching savings data:", error);
      // Handle the error appropriately, e.g., display an error message
    }

  };

  useEffect(() => {
    const response = 
    calculateLPGSavings(fuelConsumptionValue,fuelCostValue,lpgCostValue,lpgInstallationCostValue,averageKmForMonthValue,carInfo);
    setSavingResponse(response); // Assuming the API returns JSON data
  }, [fuelConsumptionValue, fuelCostValue, lpgCostValue, lpgInstallationCostValue, averageKmForMonthValue])
  

  return (
    <>
      <section className="section-area bg-white section-sp1">
        
        <div className="container">
       
          <div className="d-flex flex-row flex-wrap justify-content-between align-items-center ">
            <div className="col-lg-6 mb-30">
              <div className="heading-bx mb-30">
                {/* <h6 className="sliderTitle-ext text-primary">Neden Bizi Seçmelisiniz</h6> */}
                <h2 className="sliderTitle mb-0">LPG-AI Tasarruf Hesaplayıcı <span style={{fontSize:"12px"}}> Beta</span></h2>
              </div>
              <div className="progress-area">
                <input
                  className="form-control form-control-lg mb-3 carTypeInput"
                  type="text"
                  placeholder="Aracınızın Bilgilerini Giriniz - Orn. Volvo S60 1.6 T4 180hp "
                  value={carInfo}
                  onChange={(e) => setCarInfo(e.target.value)}
                />
                <div>
                  <div className="progress-info">
                    <h6 className="sliderTitle">
                      Aracınızın Benzin Tüketimi:{" "}
                      <span className="savingsNumbers">
                        {" "}
                        {fuelConsumptionValue}L/100km
                      </span>
                    </h6>
                  </div>
                  <Slider
                    min={0}
                    max={30}
                    step={0.1}
                    defaultValue={[0, 100]}
                    marks={true}
                    onChange={(val) => setFuelConsumptionValue(val)}
                    value={fuelConsumptionValue}
                  />
                </div>
                <div>
                  <div className="progress-info">
                    <h6 className="sliderTitle">
                      Benzin Fiyatı:{" "}
                      <span className="savingsNumbers"> {fuelCostValue}₺</span>
                    </h6>
                  </div>
                  <div>
                    <Slider
                      min={20}
                      max={70}
                      step={1}
                      defaultValue={[20, 70]}
                      marks={true}
                      onChange={(val) => setFuelCostValue(val)}
                      value={fuelCostValue}
                    />
                  </div>
                </div>
                <div>
                  <div className="progress-info">
                    <h6 className="sliderTitle">
                      LPG Fiyatı:{" "}
                      <span className="savingsNumbers"> {lpgCostValue}₺</span>
                    </h6>
                  </div>
                  <div></div>
                </div>
                <Slider
                  min={10}
                  max={35}
                  step={1}
                  defaultValue={[10, 35]}
                  marks={true}
                  onChange={(val) => setLpgCostValue(val)}
                  value={lpgCostValue}
                />
                <div>
                  <div className="progress-info">
                    <h6 className="sliderTitle">
                      LPG Dönüşüm Maliyeti:{" "}
                      <span className="savingsNumbers">
                        {" "}
                        {lpgInstallationCostValue}₺
                      </span>
                    </h6>
                  </div>
                  <div></div>
                </div>{" "}
                <div>
                  <div className="progress-info">
                    <Slider
                      min={10000}
                      max={40000}
                      step={1000}
                      defaultValue={[15000, 40000]}
                      marks={true}
                      onChange={(val) => setLpgInstallationCostValue(val)}
                      value={lpgInstallationCostValue}
                    />

                    <h6 className="sliderTitle">
                      Aylık Ortalama Kilometre:{" "}
                      <span className="savingsNumbers">
                        {" "}
                        {averageKmForMonthValue} km
                      </span>
                    </h6>
                  </div>
               
                </div>
              </div>

              <Slider
                min={100}
                max={10000}
                step={100}
                defaultValue={[100, 10000]}
                marks={true}
                onChange={(val) => setAverageKmForMonthValue(val)}
                value={averageKmForMonthValue}
              />
                 <h6 className="sliderTitle">
                      Tasarruf hesaplama süresi:{" "}
                      <span className="savingsNumbers">
                        {" "}
                        {savingsDuration} Yıl
                      </span>
                    </h6>
                    <Slider
                min={1}
                max={10}
                step={0.5}
                defaultValue={[4,10]}
                marks={true}
                onChange={(val) => setSavingsDuration(val)}
                value={savingsDuration}
              />
              <div className="d-flex flex-column">
              <button className="btn btn-light mt-50 bg-primary" onClick={() => calculateSavings()} disabled={!carInfo}>
              Ön Bilgi Al
              </button>{" "}
              </div>
               
            </div>
            <div className="col-lg-5" ref={aiRef}>
              <div className="mt-100">
              <LegendItem color="#0088FE" text="Montaj Maliyeti" />
              <LegendItem color="#00C49F" text="Tasarruf" />
              </div>
              <div style={{marginLeft:"-15px"}}>
                <Doughnut saving={savingResponse} savingDuration={savingsDuration} />
            <div className="d-flex justify-content-center align-items-center mb-100">
            {aiLoading?<ThreeDots/>   :         <Typewriter text={mechanicIdea} speed={30} />
            }
            </div>
    
       
              </div>
     
            </div>
          </div>
         
        </div>
        
      </section>
    </>
  );
};

export default SavingsBot;
