import pic1 from "../../images/blog/grid/pic1.jpg";
import pic2 from "../../images/blog/grid/pic2.jpg";
import pic3 from "../../images/blog/grid/pic3.jpg";
import pic4 from "../../images/blog/grid/pic4.jpg";
import pic5 from "../../images/blog/grid/pic5.jpg";
import pic6 from "../../images/blog/grid/pic6.jpg";
import tank from "../../images/services/tank.png"
import kit from "../../images/services/kit.png"
import bakim from "../../images/services/bakim.png"
import rapor from "../../images/services/rapor.png"
import genelkontrol from "../../images/services/genelkontrol.png"
import yedek from "../../images/services/yedek.png"

export const services = [
    {
        header:"MONTAJ",
        definition:"2008’den beri Evren Otogaz’da 3 aşama ve 22 nokta kalite kontrol standartları ile dönüşümünüz emin ellerde yapilmaktadir.",
        image: kit
    
    }, {
        header:"LPG TANK DEGİSİMİ",
        definition:"Otogazlı aracınızda her 10 yılda bir tank değişimi kanunî bir gerekliliktir, ya da aracınızdaki tankınızın ebatı veya konumu ile ilgili bir işleme ihtiyacınız varsa en geniş tank seçenekleri ve montaj kalitesi sunulmakta",
        image: tank
    
    }, {
        header:"BAKIM",
        definition:"20 yıllık deneyimimizle aracınızın LPG sisteminin bakım ve onarımı yapılmaktadır.",
        image: bakim
    }, {
        header:"SIZDIRMAZLIK RAPORU",
        definition:"Gerekli kontroller sonrası Sızdırmazlık raporu ve LPG projeleri TUVTURK sartlarına uygun olarak hazırlanmaktadır",
        image: rapor
    }, {
        header:"GENEL KONTROL",
        definition:"Uzun yola cıkmadan,önce kontrolleriniz 20 yıllık tecrübeli ustalarımız tarafından yapılmaktadır",
        image: genelkontrol
    
    }, {
        header:"LPG YEDEK PARÇA",
        definition:"Otogazlı aracınızin Atiker ve Zavoli marka LPG yedek parçasıni ihtiyacınızı karşılamak için hazırız",
        image: yedek
    },
]

export const blogs = [
  {
    path: "/blog-faaliyet-firma",
    header: "Türkiye'de Faaliyet Gösteren LPG Firmaları",
    body: "LPG dönüşümü, benzinli araçların yakıt sistemlerinin modifiye edilerek LPG kullanmalarını sağlayan bir işlemdir. LPG dönüşümü yaptıran araç sahipleri, yakıt tasarrufu, çevre dostu ve motor ömrü gibi avantajlardan yararlanabilirler. LPG dönüşümü yaptırmak isteyenler, Türkiye'de faaliyet gösteren pek çok LPG markası arasından seçim yapabilirler.",
    image: pic1,
    date: "05 Kas 2023",
  },
  {
    path: "/blog-lpg-tank-degisimi",
    header: "LPG Tank Değişimi: Neden ve Nasıl Yapılır?",
    body: "LPG tankı, kullanım için 10 yıllık bir süre için onaylanır. Bu süre sonunda gaz tüpü yenisiyle değiştirilmeli veya yeniden test edilmelidir. LPG tankının değiştirilmesi zaman alıcı veya karmaşık bir prosedür değildir, ancak kendi güvenliğiniz için LPG sistemleri konusunda uzman bir servisin hizmetlerinden yararlanmak en iyisidir.",
    image: pic2,
    date: "05 Nis 2024",
  },
  {
    path: "/blog-lpg-vs-elektrikli-araclar",
    header: "LPG mi, Elektrikli Araçlar mı? Ekonomik Analiz",
    body: "Bir zamanlar araç gaz sistemlerinin en büyük rakibi, ekolojik ve çok ucuz olması beklenen elektrikli motorlu taşıtlar gibi görünüyordu. Ancak elektrikli araçlardaki ekoloji çok geleneksel ve finansal argüman giderek zayıflıyor.",
    image: pic3,
    date: "18 May 2023",
  },
  {
    path: "/blog-neden-hala-karli",
    header: "LPG Dönüşümü Hala Karlı mı?",
    body: "Araçların LPG'ye dönüştürülmesi, birçok ülkede uzun yıllardır uygulanan bir yöntemdir ve otomotiv pazarındaki büyük değişimlere rağmen, LPG'nin araba yakıtı olarak kullanımı hala karlıdır. Peki neden? En az iki temel sebep var.",
    image: pic4,
    date: "22 Haz 2024",
  },
  {
    path: "/blog-otomotiv-sektorunun-gelecegi",
    header: "Otomotiv Sektörünün Geleceği: LPG mi, Elektrik mi?",
    body: "İçinde bulunduğumuz belirsiz zamanlar, geleceğe dair endişelere neden oluyor. Otomotiv endüstrisi dahil çeşitli sektörlerden girişimciler de bu endişeleri taşıyor. En büyük otomobil üreticilerinden gelen mesajlar tutarsız, hatta çelişkili olduğunda nasıl endişelenmezsiniz?",
    image: pic5,
    date: "22 Tem 2024",
  },
];
