import React from "react";

// Images
import logo from "../../images/evren-side.png";

import icon1 from "../../images/icon/contact/icon1.png";
import icon2 from "../../images/icon/contact/icon2.png";
import icon3 from "../../images/icon/contact/icon3.png";

const Footer = () => {
    return (
      <>
        <footer className="footer">
          <div className="footer-info bg-primary">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="footer-logo">
                    <img src={logo} alt="" />
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon3} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <address>
                        <p>
                          Merkez, Yüksek Sk No:19/b, 34245
                          Gaziosmanpaşa/İstanbul, Turkey
                        </p>
                      </address>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon1} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <a href="tel:0212 564 11 34">0212 564 11 34</a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-sm-6 mb-30">
                  <div className="feature-container left footer-info-bx">
                    <div className="icon-lg">
                      <span className="icon-cell">
                        <img src={icon2} alt="" />
                      </span>
                    </div>
                    <div className="icon-content">
                      <address>
                        <a href="mailto:info@evrenotogaz.com">
                          info@evrenotogaz.com{" "}
                        </a>
                        <br />
                        <a href="mailto:evrenotogaz@gmail.com">
                          evrenotogaz@gmail.com{" "}
                        </a>
                      </address>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-top bt0">
            <div className="container">
              <div className="row">
                <div className="col-xl-3 col-md-6">
                  <div className="widget footer_widget">
                    <h5 className="footer-title">İLETİŞİM</h5>
                    <p className="mb-20">
                      Montaj, tamir, bakim ve diger her LPG ile ilgili butun
                      islemleriniz hemen arayin.{" "}
                    </p>
                    <div className="ft-content">
                      <i className="fa fa-phone"></i>
                      <span>Aramak icin tiklayin</span>
                      <a href="tel:0212 564 11 34">0212 564 11 34</a>
                    </div>
                  </div>
                </div>
              
                <div className="col-xl-3 col-md-6">
                  <iframe
				  title="mapdukkan"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3008.6512530818236!2d28.91132531567044!3d41.05475502451196!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab0841353ced9%3A0xc54f44396729382c!2sEVREN%20OTO%20GAZ!5e0!3m2!1sen!2spl!4v1671050593895!5m2!1sen!2spl"
                  
                    style={{border:0}}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12 text-center text-md-start">
                  <p className="mb-0">
                    ©Copyright Evren Otogaz 2022. Bütün hakları saklıdır.
                  </p>
                </div>
             
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }

export default Footer;
