import React from "react";

export const ThreeDots = () => {
  return (
    <div className="threedots-loader"/>
   
  );
};

export default ThreeDots;
