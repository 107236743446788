import React from 'react';

const LegendItem = ({ color, text }) => (
  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
    <div
      style={{
        width: '15px',
        height: '15px',
        backgroundColor: color,
        marginRight: '10px'
      }}
    />
    <span>{text}</span>
  </div>
);



export default LegendItem;